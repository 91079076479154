export default defineNuxtRouteMiddleware((to, from) => {
    const kukis = useCookie('_x_adminloginsession')
    if (kukis?.value === undefined) {
        useCookie('_x_adminloginsession')

        const w = useCookie('_rza_after_login_admin', { sameSite: 'lax' })
        w.value = from.path
        return navigateTo('/admin/login')
    }
})
